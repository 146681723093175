import { Fragment, useMemo, useState } from "react";
import {
  createBrowserRouter,
  Link,
  Navigate,
  Outlet,
  RouterProvider,
  useLocation,
} from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import AuthProvider, { useAuth } from "./utils/auth";

import { ReactComponent as Rotate } from "./assets/icons/Rotate.svg";
import { ReactComponent as Menu } from "./assets/icons/Menu.svg";
import { ReactComponent as Close } from "./assets/icons/Close.svg";
import { ReactComponent as DashboardIcon } from "./assets/icons/Dashboard.svg";
import { ReactComponent as MyAccountIcon } from "./assets/icons/MyAccount.svg";
import { ReactComponent as TutorialIcon } from "./assets/icons/Tutorial.svg";
import { ReactComponent as LogoutIcon } from "./assets/icons/Logout.svg";

import Login from "./screens/Login";
import TermsOfUse from "./screens/TermsOfUse";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import BasicDetails from "./screens/BasicDetails";
import MyAccount from "./screens/MyAccount";
import Tutorial from "./screens/Tutorial";
import Dashboard from "./screens/Dashboard";
import ScanInstructions from "./screens/ScanInstructions";
import SelectPosture from "./screens/SelectPosture";
import FaceScan from "./screens/FaceScan";
import ScanResult from "./screens/ScanResult";

const Header = () => {
  const { pathname } = useLocation();
  const {
    logout,
    userData: { fname },
  } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const greetingsText = useMemo(() => {
    const hourNow = new Date().getHours();
    return hourNow < 12
      ? "Good Morning"
      : hourNow < 17
      ? "Good Afternoon"
      : "Good Evening";
  }, []);

  return (
    <main className="min-h-screen max-w-sm mx-auto relative bg-white">
      <Transition show={isOpen} as={Fragment}>
        <Dialog as={Fragment} onClose={() => setIsOpen(false)}>
          <Transition.Child
            className="fixed top-0 bottom-0 left-0 right-0 bg-black/50"
            enter="duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="duration-300 delay-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Transition.Child
              as={Fragment}
              enter="delay-150 duration-200 transition-transform transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="duration-200 transition-transform transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel
                as="aside"
                className="min-h-full w-64 bg-white flex flex-col items-stretch"
              >
                <div className="flex items-start justify-between p-6">
                  <div className="w-40 text-black">
                    <Dialog.Title as="h3" className="text-sm">
                      {greetingsText}
                    </Dialog.Title>
                    <Dialog.Title
                      as="h2"
                      className="text-lg font-medium truncate"
                    >
                      {fname}
                    </Dialog.Title>
                  </div>
                  <button
                    className="flex-shrink-0 bg-primary p-1.5 rounded-full"
                    type="button"
                    onClick={() => setIsOpen(false)}
                  >
                    <Close className="h-5 w-5 text-white" />
                  </button>
                </div>
                <nav className="mt-4">
                  <ul className="space-y-4">
                    {[
                      {
                        to: "/",
                        label: "Dashboard",
                        icon: (
                          <DashboardIcon className="h-5 w-5 text-darkgray" />
                        ),
                      },
                      {
                        to: "/my-account",
                        label: "My Account",
                        icon: (
                          <MyAccountIcon className="h-5 w-5 text-darkgray" />
                        ),
                      },
                      {
                        to: "/tutorial",
                        label: "Tutorial",
                        icon: (
                          <TutorialIcon className="h-5 w-5 text-darkgray" />
                        ),
                      },
                    ].map((linkObj) => (
                      <li key={linkObj.to} className="pr-6">
                        <Link
                          to={linkObj.to}
                          className={`flex items-center space-x-4 py-2 px-6 rounded-r-full ${
                            pathname === linkObj.to
                              ? "bg-primary/10"
                              : "bg-transparent"
                          } hover:bg-primary/5`}
                          onClick={() => setIsOpen(false)}
                        >
                          {linkObj.icon}
                          <span className="text-black text-xs">
                            {linkObj.label}
                          </span>
                        </Link>
                      </li>
                    ))}
                    <li className="pr-6">
                      <button
                        type="button"
                        className="w-full flex items-center space-x-4 py-2 px-6 rounded-r-full hover:bg-error/5 active:bg-error/10"
                        onClick={() => {
                          setIsOpen(false);
                          logout();
                        }}
                      >
                        <LogoutIcon className="h-5 w-5 text-error" />
                        <span className="text-error text-xs">Logout</span>
                      </button>
                    </li>
                  </ul>
                </nav>
              </Dialog.Panel>
            </Transition.Child>
          </Transition.Child>
        </Dialog>
      </Transition>
      <header className="flex items-center justify-between p-6">
        <div className="w-40 text-black">
          <h3 className="text-sm">{greetingsText}</h3>
          <h2 className="text-lg font-medium truncate">
            {fname?.length > 0 ? (
              fname
            ) : (
              <Navigate to="/basic-details" replace />
            )}
          </h2>
        </div>
        <button
          className="flex-shrink-0"
          type="button"
          onClick={() => setIsOpen(true)}
        >
          <Menu className="h-6 w-6 text-black" />
        </button>
      </header>
      <Outlet />
    </main>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <AuthProvider>
        <Header />
      </AuthProvider>
    ),
    children: [
      { index: true, element: <Dashboard /> },
      { path: "my-account", element: <MyAccount /> },
      { path: "tutorial", element: <Tutorial /> },
      { path: "scan-instructions", element: <ScanInstructions /> },
      { path: "select-posture", element: <SelectPosture /> },
      { path: "scan-result", element: <ScanResult /> },
    ],
  },
  {
    path: "terms-of-use",
    element: <TermsOfUse />,
  },
  {
    path: "privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "face-scan",
    element: (
      <AuthProvider>
        <FaceScan />
      </AuthProvider>
    ),
  },
  {
    path: "basic-details",
    element: (
      <AuthProvider>
        <BasicDetails />
      </AuthProvider>
    ),
  },
  {
    path: "/login",
    element: (
      <AuthProvider>
        <Login />
      </AuthProvider>
    ),
  },
]);

const App = () => (
  <>
    <RouterProvider router={router} />
    <div className="fixed top-0 bottom-0 left-0 right-0 z-50 h-screen w-screen hidden md:flex xs:landscape:flex flex-col items-center justify-center bg-white">
      <Rotate className="flex-shrink-0 h-32 w-36" />
      <p className="mt-6 w-72 text-darkgray text-lg text-center">
        Please rotate your phone to access the application.
      </p>
    </div>
  </>
);

export default App;
