import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import { useAuth } from "../utils/auth";
import { sdkWrapperURL } from "../utils/api-url-list";
import { constants } from "../utils/ranges";

import LoadingScreen from "../components/LoadingScreen";
import ProgressCircle from "../components/ProgressCircle";

import BannerWoman from "../assets/images/BannerWoman.png";
import { ReactComponent as Arrow } from "../assets/icons/Arrow.svg";
import { ReactComponent as Chevron } from "../assets/icons/Chevron.svg";

const Dashboard = () => {
  const {
    getToken,
    userData: { employee_id },
  } = useAuth();
  const [loading, setLoading] = useState(true);
  const [lastScanResult, setLastResult] = useState({});
  const selectedScoreRange = useMemo(
    () =>
      constants["Health Score"].ranges.reduce((p, c) =>
        lastScanResult.wellness_score > c.point ? c : p
      ),
    [lastScanResult.wellness_score]
  );

  useEffect(() => {
    const controller = new AbortController();
    setLoading(true);
    (async () => {
      try {
        const token = await getToken();
        const scoreResp = await fetch(
          sdkWrapperURL("/users/sdk/test_api_private"),
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            body: JSON.stringify({
              action: "list_scan_UL",
              employee_id,
              table_config_rows_per_page: 1,
              table_config_page_no: 1,
            }),
            signal: controller.signal,
          }
        );
        const scoreRespJSON = await scoreResp.json();
        if (
          scoreRespJSON?.statusCode?.toString().startsWith("2") &&
          scoreRespJSON?.result?.length > 0
        )
          setLastResult(scoreRespJSON.result[0]);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    })();
    return () => {
      controller.abort();
      setLoading(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading ? (
    <LoadingScreen style={{ minHeight: "60vh" }} />
  ) : (
    <section className="px-6 py-2">
      <div className="px-6 flex items-center space-x-6">
        <ProgressCircle
          loading={loading}
          value={lastScanResult.wellness_score}
          color={selectedScoreRange.color}
          subtitle={selectedScoreRange.label}
        />
        <div>
          <p className="text-black text-sm font-semibold">Health Score</p>
          <p className="text-darkgray text-xxs whitespace-pre-line">
            {lastScanResult.created_at?.length > 0
              ? `Recorded on\n${new Date(
                  lastScanResult.created_at
                ).toLocaleDateString(undefined, {
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: true,
                })}`
              : "No scan data available"}
          </p>
        </div>
      </div>

      <Link
        className="my-6 bg-gradient-to-r from-primary to-[#B20C15] text-white rounded-xl flex items-center justify-between"
        to="/scan-instructions"
        state={{ scanType: "face" }}
      >
        <img
          className="self-end flex-shrink-0 h-20"
          src={BannerWoman}
          alt="Banner"
        />
        <div className="mx-1.5 space-y-1">
          <h3 className="text-xs font-semibold">Know Your Health</h3>
          <p className="text-xxs tracking-tighter leading-tight">
            Do a self-health check in less than one minute.
          </p>
        </div>
        <div className="flex-shrink-0 flex items-center space-x-1 rounded-full mr-3 px-2 py-1 bg-white text-primary">
          <span className="flex-shrink-0 text-xxs tracking-tighter font-medium">
            Start A Scan
          </span>
          <Arrow className="flex-shrink-0 h-2.5 w-2.5" />
        </div>
      </Link>

      {lastScanResult.created_at?.length > 0 && (
        <Link
          to="/scan-result"
          state={{
            result: {
              scan_id: lastScanResult.scan_id,
              posture: lastScanResult.posture,
              wellness_score: lastScanResult.wellness_score,
              vitals: lastScanResult.vitals,
              metadata: {
                heart_scores: lastScanResult.heart_scores,
                cardiovascular: lastScanResult.cardiovascular,
                physiological_scores: lastScanResult.physiological_scores,
              },
            },
          }}
          className="flex items-center justify-between py-4 px-6 rounded-xl bg-primary text-white"
        >
          <span className="text-sm">View Last Assessment Result</span>
          <Chevron className="flex-shrink-0 h-4 w-4 -rotate-90" />
        </Link>
      )}

      <div className="my-8">
        <h2 className="mb-4 text-primary text-sm font-semibold">
          Watch our Products
        </h2>
        <video
          className="w-full rounded-2xl bg-black pointer-events-none"
          src={process.env.PUBLIC_URL + "/About.mp4"}
          loop
          autoPlay
          muted
          playsInline
          disablePictureInPicture
          disableRemotePlayback
        />
      </div>

      <div className="my-8">
        <h2 className="mb-4 text-primary text-sm font-semibold">
          About Digital Health Scan
        </h2>
        <p className="text-darkgray text-xs text-justify">
          CarePlix has powered our Digital Health Scan & it is like having a
          personal health check-up at your fingertips! With just a quick scan,
          you can monitor important vitals like heart rate, blood pressure, and
          more. Our Vitals Monitoring feature makes it easy to stay on top of
          your well-being, so you can feel confident and in control every step
          of the way.
        </p>
      </div>
    </section>
  );
};

export default Dashboard;
