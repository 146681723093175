import { useEffect, useRef, useState } from "react";

import { api_key, sdkWrapperURL } from "../utils/api-url-list";
import { useAuth } from "../utils/auth";

import Logo from "../assets/images/Logo.png";
import AuthBackground from "../assets/images/AuthBackground.png";
import { ReactComponent as Loader } from "../assets/icons/Loader.svg";

const Login = () => {
  const { login } = useAuth();
  const [isModalOpen, setModalOpen] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [isOTPSent, setOTPSent] = useState(false);
  const otpTimerRef = useRef(null);
  const [otp, setOTP] = useState("");
  const [otpError, setOTPError] = useState("");
  const [otpTimer, setOTPTimer] = useState(0);

  const sendOTP = async () => {
    try {
      setLoading(true);
      setPhoneNumberError("");
      if (!/^\d{10}$/.test(phoneNumber)) {
        setPhoneNumber(phoneNumber.trim());
        setPhoneNumberError("Please enter a valid phone number.");
      } else {
        const resp = await fetch(sdkWrapperURL("/auth/send-otp"), {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            api_key,
            action: "send",
            channel_id: "login_mobile",
            phone_no: phoneNumber,
          }),
        });
        const resp_json = await resp.json();
        if (resp_json.statusCode?.toString().startsWith("2")) {
          setOTPTimer(120);
          clearInterval(otpTimerRef.current);
          otpTimerRef.current = setInterval(
            () => setOTPTimer((t) => t - 1),
            1000
          );
          setOTP("");
          setOTPSent(true);
        } else {
          throw new Error(resp_json.message);
        }
      }
    } catch (err) {
      cancelOTP();
      setPhoneNumberError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const verifyOTP = async () => {
    try {
      setLoading(true);
      setOTPError("");
      if (!/^\d{6}$/.test(otp)) {
        setOTP(otp.trim());
        setOTPError("Incorrect OTP provided. Please try again");
      } else {
        const resp = await fetch(sdkWrapperURL("/auth/sign-in"), {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            api_key,
            login_type: "auth",
            login_method: "otp_mobile",
            phone_no: phoneNumber,
            otp: otp,
          }),
        });
        const resp_json = await resp.json();
        if (resp_json.statusCode?.toString().startsWith("2")) {
          await login({
            id_token: resp_json.user.id_token,
            refresh_token: resp_json.user.refresh_token,
          });
        } else {
          throw new Error(resp_json.message);
        }
      }
    } catch (err) {
      setOTPError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const cancelOTP = (goback = true) => {
    setOTPTimer(0);
    clearInterval(otpTimerRef.current);
    setOTP("");
    setOTPError("");
    if (goback) {
      setOTPSent(false);
    }
  };

  useEffect(() => {
    if (otpTimer <= 0) {
      cancelOTP(false);
    }
  }, [otpTimer]);

  return isModalOpen ? (
    <div className="min-h-screen flex flex-col items-stretch justify-start bg-[#d23438]">
      <img
        className="flex-shrink-0 self-start m-8 mb-10 h-20"
        src={Logo}
        alt="logo"
      />
      <img
        className="flex-[0.5] w-full object-cover"
        src={AuthBackground}
        alt="banner"
      />
      <div className="flex-shrink-0 p-8">
        <h1 className="text-secondary text-2xl leading-tight font-semibold whitespace-pre-line">
          {"Monitor Your\nHealth Anytime,\nAnywhere"}
        </h1>
        <p className="mt-3 text-white">
          Monitoring your health is now as simple as taking a selfie. Don't
          believe? Get started to see it yourself.
        </p>
        <button
          className="w-full mt-10 px-4 py-2 rounded-full bg-white text-primary text-lg font-medium"
          type="button"
          onClick={() => setModalOpen(false)}
        >
          Get Started
        </button>
      </div>
    </div>
  ) : (
    <section className="min-h-screen max-w-sm mx-auto p-8 relative bg-white">
      {isOTPSent ? (
        <>
          <h1 className="text-2xl text-primary font-semibold">Verify OTP</h1>
          <h4 className="mt-3 text-darkgray font-light">
            Enter the 6-digits OTP sent to your phone number
          </h4>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (!isLoading) {
                if (otpTimer > 0) verifyOTP();
                else sendOTP();
              }
            }}
          >
            <input
              id="otp"
              name="otp"
              className="block w-full mt-6 px-4 py-2 rounded-lg outline-none focus:border-black border text-sm text-black placeholder:text-lightgray"
              placeholder={otpTimer > 0 ? "Enter OTP" : "Timed Out"}
              type="text"
              inputMode="numeric"
              autoComplete="one-time-code"
              autoFocus
              maxLength={6}
              onChange={(e) =>
                e.target.value.length <= 6 && setOTP(e.target.value)
              }
              value={otp}
              disabled={isLoading || otpTimer <= 0}
            />
            {otpError.length > 0 && (
              <p className="mt-1.5 mx-1.5 text-xxs text-error">{otpError}</p>
            )}
            {otpTimer > 0 && (
              <p className="mt-1.5 mx-1.5 font-light text-sm text-primary text-right">
                Resend OTP in {Math.floor(otpTimer / 60)}:
                {(otpTimer % 60).toString().padStart(2, "0")}
              </p>
            )}
            <button
              className="w-full flex items-center justify-center space-x-2 mt-6 px-4 py-2 rounded-full bg-primary disabled:bg-darkgray text-white"
              type="submit"
              disabled={isLoading}
            >
              <span className="text-sm font-medium">
                {otpTimer > 0 ? "Verify" : "Resend OTP"}
              </span>
              {isLoading && <Loader className="flex-shrink-0 h-4 w-4" />}
            </button>
            <button
              className="block mx-auto mt-6 text-primary text-sm font-medium"
              type="button"
              onClick={cancelOTP}
              disabled={isLoading}
            >
              Cancel
            </button>
          </form>
        </>
      ) : (
        <>
          <h1 className="text-2xl text-primary font-semibold">Get Started</h1>
          <h4 className="mt-3 text-darkgray font-light">
            Login or sign up by simply verifying your phone number
          </h4>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (!isLoading) sendOTP();
            }}
          >
            <input
              id="phone"
              name="phone"
              className="block w-full mt-6 px-4 py-2 rounded-lg outline-none focus:border-black border text-sm text-black placeholder:text-lightgray"
              placeholder="Enter Phone Number"
              type="tel"
              inputMode="numeric"
              autoComplete="tel-national"
              autoFocus
              maxLength={10}
              onChange={(e) =>
                e.target.value.length <= 10 && setPhoneNumber(e.target.value)
              }
              value={phoneNumber}
              disabled={isLoading}
            />
            {phoneNumberError.length > 0 && (
              <p className="mt-1.5 mx-1.5 text-xxs text-error">
                {phoneNumberError}
              </p>
            )}
            <button
              className="w-full flex items-center justify-center space-x-2 mt-6 px-4 py-2 rounded-full bg-primary disabled:bg-darkgray text-white"
              type="submit"
              disabled={isLoading}
            >
              <span className="text-sm font-medium">Send OTP</span>
              {isLoading && <Loader className="flex-shrink-0 h-4 w-4" />}
            </button>
          </form>
        </>
      )}
    </section>
  );
};

export default Login;
