import { RadioGroup } from "@headlessui/react";

import { ReactComponent as GenderMale } from "../assets/icons/GenderMale.svg";
import { ReactComponent as GenderFemale } from "../assets/icons/GenderFemale.svg";
import { ReactComponent as GenderOthers } from "../assets/icons/GenderOthers.svg";

const GenderSelect = ({ required = false, label = "", onSelect = (selected = "") => {}, value = "", error = "" }) => (
  <RadioGroup name="gender" value={value} onChange={onSelect}>
    <RadioGroup.Label className="text-darkgray text-xs">
      {label}
      {required && <span className="text-error">&nbsp;*</span>}
    </RadioGroup.Label>
    <div className="mt-2 grid grid-cols-3 gap-4">
      {[
        {
          value: "male",
          label: "Male",
          icon: <GenderMale className="flex-shrink-0 h-4 w-4" />,
        },
        {
          value: "female",
          label: "Female",
          icon: <GenderFemale className="flex-shrink-0 h-4 w-4" />,
        },
        {
          value: "others",
          label: "Others",
          icon: <GenderOthers className="flex-shrink-0 h-4 w-4" />,
        },
      ].map((genderItem) => (
        <RadioGroup.Option
          key={`gender-${genderItem.value}`}
          value={genderItem.value}
          className={({ checked }) =>
            `flex items-center justify-center space-x-2.5 p-2 border rounded-lg ${
              error?.length > 0
                ? "border-error bg-error/5 text-error"
                : checked
                ? "border-primary bg-primary/5 text-primary"
                : "border-lightgray/20 text-black"
            } cursor-pointer`
          }
        >
          {genderItem.icon}
          <RadioGroup.Label className="text-xs">{genderItem.label}</RadioGroup.Label>
        </RadioGroup.Option>
      ))}
    </div>
    {error?.length > 0 && <p className="mt-1.5 text-xxs text-error">{error}</p>}
  </RadioGroup>
);

export default GenderSelect;
